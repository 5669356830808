.card {
    @mixin cardShadow;
    padding: var(--p-outer);
    border-radius: var(--b-border-radius);
    background: var(--c-white);

    &:any-link {
        color: inherit;
        text-decoration: none;
    }

    &:hover .title {
        color: var(--c-blue);
    }

    .title {
        margin-bottom: 0.75em;
        color: var(--c-grey3);
        font-weight: var(--f-bold);
        font-size: var(--t-level3);
        line-height: 1.75em;
        text-decoration: none;
        cursor: pointer;
        transition: color 0.2s;
    }

    .body {
        @media (--component-break2) {
            & {
                @mixin flexVList var(--items-gap);
                align-items: center;

                .img.img {
                    float: none;
                    width: auto;
                    max-width: 100%;
                    max-height: 30vh;
                    margin: 0;
                }
            }
        }

        .img {
            float: left;
            width: 14em;
            max-width: 20%;
            height: auto;
            margin-right: var(--list-gap);
            border-radius: calc(var(--b-border-radius) / 4);
        }

        .description {
            margin: 0;
            font-weight: var(--f-normal);
            font-size: var(--t-level4);
            line-height: 1.25em;
            text-indent: 0.5em;
        }
    }
}
