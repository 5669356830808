.about {
    .advantages {
        margin-bottom: 40px;

        .row {
            display: grid;
            grid-template-columns: 40px minmax(auto, 743px);
            column-gap: 2ch;

            @media (--medium-until) {
                grid-template-columns: 40px minmax(auto, 560px);
            }

            @media (--small-until) {
                grid-template-columns: 1fr;

                svg {
                    max-width: 25%;
                }
            }

            &:not(:last-child) {
                margin-bottom: 25px;
            }

            .advContent {
                .advName {
                    font-weight: var(--f-bold);
                }
            }

            & svg {
                width: 100%;
            }
        }
    }
}
