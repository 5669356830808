@keyframes showEffect {
    99% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

.connect {
    position: relative;
    overflow-x: hidden;
    margin-top: calc(-1 * var(--header-height));

    @media (--medium-until) {
        padding-bottom: 430px;
        background-image: none;
    }

    @media (--medium-only) {
        padding-bottom: 80px;
    }

    .background,
    .points {
        position: absolute;
        left: 0;
        width: 100%;
        height: auto;
    }

    .background {
        background: var(--c-white);
    }

    .points {
        @media (--medium-until) {
            display: none;
        }
    }

    .tablet {
        position: absolute;
        top: 11%;
        left: 23%;
        width: 24%;
        opacity: 1 !important;
        visibility: hidden;
        animation: 1.1s showEffect;
        animation-fill-mode: forwards;

        @media (--medium-until) {
            display: none;
        }
    }

    .girl {
        position: absolute;
        top: 18%;
        left: 7%;
        display: block;
        width: 24%;
        height: auto;
        background-image: url(img/girl.svg);
        background-repeat: no-repeat;

        @media (--medium-until) {
            display: none;
        }
    }

    .content {
        position: absolute;
        top: 24%;
        left: 48%;
        display: flex;
        flex-direction: column;
        gap: 80px;
        width: 40%;

        @media (--medium-until) {
            top: 50%;
            left: 0;
            width: 96%;
        }

        .title {
            align-self: flex-end;
            width: 86%;
            color: var(--c-grey3);
            font-weight: 500;
            font-size: 36px;
            line-height: 40px;

            @media (--medium-until) {
                align-self: flex-start;
                width: 100%;
                margin-bottom: 20px;
                padding: 0 10px;
                font-size: 24px;
                line-height: 30px;
                text-align: center;
                word-break: break-word;
            }

            @media (--large-only) {
                margin-bottom: 40px;
            }
        }

        .infographics {
            position: relative;

            .line {
                position: relative;
                z-index: -1;
                width: 100%;

                @media (--medium-until) {
                    width: 120%;
                    margin-left: -20%;
                }
            }

            .btn {
                position: absolute;
                left: 55%;
                display: flex;
                justify-content: center;
                font-size: var(--t-level3);
                transform: translateY(calc(-50% - 5px));

                @media (--medium-until) {
                    left: 48%;
                    width: 52%;
                }

                button {
                    width: 100%;
                }
            }

            .point {
                position: absolute;
                color: var(--c-grey3);
                font-weight: 300;
                font-size: 22px;
                line-height: 26px;

                @media (--medium-until) {
                    font-size: 18px;
                }

                @media (--component-break2) {
                    font-size: 14px;
                }

                &.line1 {
                    top: -14%;
                }

                &.line2 {
                    bottom: 60%;
                }

                &.tests {
                    left: 27.5%;
                }

                &.analytics {
                    left: 46%;
                }

                &.statistics {
                    left: 75%;
                }

                &.surveys {
                    left: 33%;
                }

                &.votes {
                    left: 70%;
                }
            }
        }
    }
}

.clip {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
}

.bg2 {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    overflow: hidden;
    width: 90%;
    clip-path: url(#bgClip);

    @media (--medium-until) {
        width: 155%;
        clip-path: url(#bgClipMobile);
    }
}

.zigzag {
    position: absolute;
    display: grid;
    width: 100%;
    opacity: 0.5;

    & > * {
        grid-area: 1/1;
    }

    & > div {
        background-image: url(components/ui/zigzag.webp);
        background-size: 60% 60%;
        background-repeat: no-repeat;
    }
}

.zigzag1 {
    top: 46%;
    left: -25%;
    transform: rotate(0);
    transform-origin: 50% 50%;

    @media (--medium-until) {
        top: -46%;
        left: -8%;
        z-index: 3;
        width: 115%;
        transform: rotate(323deg);
    }
}

.zigzag2 {
    top: 11%;
    left: 31%;
    transform: rotate(247deg);
    transform-origin: 25% 25%;

    @media (--medium-until) {
        top: -10%;
        left: -23%;
        z-index: 2;
        width: 93%;
        opacity: 0.15;
        transform: rotate(-166deg);
    }
}

.zigzag3 {
    top: -65%;
    left: -22%;
    transform: rotate(653deg);
    transform-origin: 50% 50%;

    @media (--medium-until) {
        top: -29%;
        left: -13%;
        z-index: -1;
        width: 93%;
        opacity: 0.15;
        transform: rotate(-224deg);
    }
}

@media (--medium-only) {
    .connect {
        width: 100%;

        .content {
            gap: 20px;

            .title {
                margin-bottom: 30px;
                font-size: 32px;
                line-height: 40px;
            }

            .infographics {
                .point {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}
