.light {
    > .links > .item {
        &.active {
            > .action {
                color: var(--c-white);
            }
        }

        > .sub .sub {
            .item {
                border-left: 0;

                &.active {
                    border-left: 2px solid var(--c-white);
                }
            }
        }
    }

    .item,
    .action {
        .link {
            color: var(--c-white);
        }
    }
}

.dark {
    > .links > .item {
        &.active {
            > .action {
                color: var(--c-blue2);
            }

            > a {
                color: var(--c-blue) !important;
                font-weight: var(--f-bold);
            }
        }

        > .sub .sub {
            .item {

                &.active {
                    position: relative;

                    &:after {
                        content: '';
                        height: 100%;
                        width: 2px;
                        background-color: var(--c-blue2);
                        position: absolute;
                        left: 0;
                        top: 0;
                        border-radius: 2px;
                        z-index: 1;
                    }
                }

                & > a {
                    font-weight: var(--f-normal);
                }
            }
        }
    }

    .item,
    .action {
        .link {
            color: var(--c-black);
        }
    }
}

.menu {
    a:any-link {
        color: inherit;
    }

    .active > a.link {
        font-weight: var(--f-bold);
    }

    .links {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    > .links > .item {
        font-size: 14px;
        line-height: 20px;

        &.active > .action {
            font-weight: var(--f-bold);
        }

        > .sub {
            padding-left: 20px;

            .links {
                margin-top: 10px;
            }

            .sub {
                padding-left: 0;
                position: relative;

                &:after {
                    content: '';
                    height: 100%;
                    width: 2px;
                    background-color: var(--c-grey8D);
                    opacity: 0.1;
                    position: absolute;
                    left: 0;
                    top: 0;
                    border-radius: 2px;

                }

                .item {
                    padding-left: 20px;
                }
            }
        }
    }

    .item {
        font-weight: var(--f-normal);

        &.active .action {
            font-weight: var(--f-bolder2);
        }

        .action,
        .link {
            cursor: pointer;
            text-decoration-color: transparent;

            &:hover {
                text-decoration: underline;
                transition: all 0.3s ease-in;
            }
        }
    }
}
