.subheader {
    margin: 30px 0 20px;
    font-weight: var(--f-bold);
}

.group {
    margin-bottom: calc(10px + 1.2vw);
    padding: calc(10px + 1.2vw);
    border-radius: 20px;
    background: var(--c-white);
    box-shadow: 0 4px 10px #2e5bff33;
}

.groupHeader {
    display: flex;
    align-items: center;

    svg {
        flex-shrink: 0;
        width: calc(35px + 1vw);
        margin-right: calc(14px + 0.5vw);
        color: var(--c-blue2);
    }
}

.groupDescription {
    font-weight: var(--f-normal);
    font-size: 16px;
    line-height: 150%;
}

.templateItem {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-weight: var(--f-normal);
    font-size: 14px;
    line-height: 130%;

    a {
        display: inline-flex;
        align-items: center;
    }

    svg {
        min-width: 17px;
        margin-right: 15px;
        fill: var(--c-blue2);
    }
}

.link {
    color: var(--c-blue2);
    text-decoration: none;
}

.examples {
    display: flex;
    flex-flow: row nowrap;
    gap: 2ch;
    justify-content: space-between;
    margin: 3ch 0;

    @media (max-width: 1200px) {
        flex-flow: column nowrap;
        justify-content: flex-start;
    }

    .column {
        display: flex;
        flex-direction: column;
        gap: 2ch;
    }

    & > div {
        width: 49%;

        @media (max-width: 1200px) {
            width: 100%;
        }
    }
}
