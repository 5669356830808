.box {
    position: relative;
    box-sizing: border-box;
    max-width: 1208px;
    margin: 0 auto;

    &::before {
        content: '';
        position: absolute;
        top: -35px;
        width: 59%;
        height: 354px;
        border-radius: 25px;
        background: linear-gradient(257.34deg, #67bffe 26.09%, #92fbf4 111.58%);
        box-shadow: 0 4px 4px 0 #00eaf833;

        @media (--medium) {
            width: 270px;
            height: 149px;
        }

        @media (min-width: 1000px) {
            width: 420px;
            height: 232px;
        }

        @media (--xlarge-landing) {
            left: 160px;
            width: 421px;
            height: 232px;
        }

        @media (--medium-until) {
            left: -15%;
        }
    }

    &::after {
        @mixin resultShadow;
        content: '';
        position: absolute;
        top: -41px;
        right: 0;
        width: 50%;
        height: 357px;
        border-radius: 25px 0 0 25px;
        background: var(--c-white);

        @media (--medium-until) {
            right: -15%;
            width: 60%;
        }

        @media (--medium) {
            top: -40px;
            width: 379px;
            height: 172px;
            border-radius: var(--b-border-radius);
        }

        @media (min-width: 1000px) {
            top: -61px;
            width: 596px;
            height: 268px;
        }

        @media (min-width: 1370px) {
            top: -61px;
            width: 480px;
            height: 268px;
        }

        @media (--xlarge-landing) {
            top: -61px;
            width: 482px;
            height: 268px;
        }
    }
}

.controls {
    display: flex;
    justify-content: space-between;

    @media (--medium-until) {
        margin: 0 20px;
    }

    .prev,
    .next {
        width: 39px;
        background-size: contain;
        background-repeat: no-repeat;
        color: #e5e5e5;
        cursor: pointer;
    }

    .prev {
        color: #e5e5e5;
        transform: rotate(180deg);
    }

    .dots {
        display: flex;
        align-items: center;

        .dot {
            width: 9px;
            height: 9px;
            margin: 0 6px;
            border-radius: 5px;
            background: var(--c-greyE);
            cursor: pointer;
            transition: background-color 0.25s ease, width 0.25s ease;

            &.current {
                width: 27px;
                background: var(--c-blue);
            }
        }
    }
}

.list {
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    @media (--xlarge-landing) {
        max-width: 1050px;
    }

    .item {
        @mixin resultShadow;
        z-index: 100;
        overflow: hidden;
        box-sizing: border-box;
        width: 80%;
        max-height: 400px;
        padding: 60px 50px;
        border-radius: 25px;
        background: var(--c-white);
        user-select: none;

        @media (--medium-only) {
            padding: 40px 30px;
        }

        @media (--medium-until) {
            padding: 40px 20px;
        }

        &.closeLeft {
            position: absolute;
            top: 0;
            left: 0;
            animation-name: closeLeftSlider;
            animation-duration: 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }

        &.openLeft {
            animation-name: openLeftSlider;
            animation-duration: 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }

        &.openRight {
            animation-name: openRightSlider;
            animation-duration: 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }

        &.closeRight {
            position: absolute;
            top: 0;
            left: 0;
            animation-name: closeRightSlider;
            animation-duration: 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
    }
}

@keyframes closeLeftSlider {
    0% {
        opacity: 1;
        transform: scale(1) translate(0, 0);
    }

    100% {
        opacity: 0;
        transform: scale(0.6) translate(-50%, -10%);
    }
}

@keyframes openRightSlider {
    0% {
        opacity: 0;
        transform: scale(0.6) translate(50%, -10%);
    }

    100% {
        opacity: 1;
        transform: scale(1) translate(0, 0);
    }
}

@keyframes openLeftSlider {
    0% {
        opacity: 0;
        transform: scale(0.6) translate(-50%, -10%);
    }

    100% {
        opacity: 1;
        transform: scale(1) translate(0, 0);
    }
}

@keyframes closeRightSlider {
    0% {
        opacity: 1;
        transform: scale(1) translate(0, 0);
    }

    100% {
        opacity: 0;
        transform: scale(0.6) translate(50%, -10%);
    }
}
