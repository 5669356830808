.footer {
    clear: both;
    padding: 20px 0;
    font-size: 14px;
    line-height: 20px;

    .container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        max-width: var(--content-max-width);
        margin: 0 auto;

        @media (--large-until) {
            margin: 0 20px;
        }

        @media (--medium-until) {
            gap: 2ch;
        }
    }

    .contacts {
        display: flex;
        flex-basis: 100%;
        justify-content: space-between;

        @media (--medium) {
            flex-basis: auto;
            gap: 3ch;
            order: 1;
            margin-bottom: 0;
        }

        .contact {
            &.tel .link {
                font-weight: var(--f-bolder);
                text-decoration: none;
            }

            &.email .link {
                text-decoration: underline;
            }

            .link {
                color: var(--c-blue2);
                font-weight: var(--f-bold);
            }
        }
    }

    .copy {
        color: var(--c-grey3-80);
        font-weight: var(--f-normal);

        @media (--medium-until) {
            order: 0;
            width: 100%;
            text-align: center;
        }
    }
}
