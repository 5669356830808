.wrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    gap: 30px;
    box-shadow: 0 4px 10px #2e5bff33;
    border-radius: 30px;
    padding: 30px;
    z-index: 999;
    background-color: var(--c-white);
    inset: 0 0 auto auto;
    transform: translate3d(-90px, 64px, 0px);
    min-width: 300px;

    .header {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        color: var(--c-black);

        &:hover {
            color: var(--c-blue);
            transition: color 0.3s;
        }

        &:hover .icon {
            color: var(--c-blue) !important;
            transition: color 0.3s;
        }

        .info {
            padding-top: 5px;

            .name {
                font-size: 14px;
                font-weight: bold;
            }

            .mail {
                font-size: 13px;
            }
        }

        .icon {
            display: inline-block;
            width: 40px;
            height: auto;
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
            color: var(--c-white);
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        background-color: #F9F9F9;
        border-radius: 30px;
        padding: 20px;
        gap: 10px;

        .account {
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            border-bottom: 1px solid #EAEAEA;
            cursor: pointer;
            color: var(--c-black);

            .accountName {
                font-size: 13px;
                font-weight: bold;
            }

            .accountMail {
                font-size: 10px;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
}
