.blogCards {
    @mixin flexVList var(--blocks-gap);

    .cards {
        @mixin flexVList var(--blocks-gap);
    }

    .pagination {
        font-size: var(--t-level3);
    }
}
