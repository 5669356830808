.dark .menu .item {
    &.login {
        border-right: 1px solid var(--c-grey3);
    }

    .link {
        color: var(--c-grey3);
    }
}

.light .menu .item {
    &.login {
        border-right: 1px solid var(--c-white);
    }

    .link {
        color: var(--c-white);
    }
}

.menu {
    display: flex;
    gap: 40px;
    align-items: center;

    @media (--medium-until) {
        gap: 20px;
    }

    .item {
        display: flex;

        @media (--medium-until) {
            display: none;

            &.mobileShow {
                display: flex;
            }
        }

        a {
            display: flex;
            place-content: center;
        }

        .nonAuth {
            display: flex;
            gap: 20px;

            @media (--small-until) {
                flex-direction: column;
                row-gap: 3ch;
            }

            @media (--medium-until) {
                gap: 10px;
            }

            .span {
                width: clamp(1px, 0.25vw, 2px);
                height: 100%;
                background-color: #3190FF;

                @media (--small-until) {
                    display: none;
                }
            }
        }

        .auth {
            display: flex;
            gap: 1vw;
            align-items: center;
        }

        .link {
            display: inline-block;
            color: var(--c-white);
            font-weight: var(--f-thiner);
            font-style: normal;
            font-size: 14px;
            line-height: 16px;
            font-family: Montserrat, sans-serif;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                color: var(--c-blue);
                transition: color 0.3s;
            }

            &:hover .icon {
                color: var(--c-blue) !important;
                transition: color 0.3s;
            }

            &.account {
                display: flex;
                position: relative;
                align-items: center;
                gap: 4px;
                border-bottom: 0;

                .icon {
                    display: inline-block;
                    width: 50px;
                    height: auto;
                    background-position: center;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    color: var(--c-white);
                }

                .accountName {
                    display: flex;
                    gap: 4px;
                    align-items: center;
                }

                .arrowIcon {
                    color: var(--c-blue2);
                }

                .rotatePlus180 {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.header {
    .container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 16px 15px;
        height: var(--header-height);
        margin: 0 auto;
        max-width: calc(110px * 12);

        @media (--small-until) {
            padding: 15px 5px;
        }

        @media (--medium-until) {
            margin: 0;
        }
    }

    .logo {
        width: 173px;
        height: 44px;
        font-size: 0;

        @media (--medium-until) {
            width: 120px;
            height: 30px;
        }
    }

    .burger {
        color: var(--c-blue);
        cursor: pointer;
        margin-left: 60px;
        display: inline-flex;

        @media (--medium-until) {
            margin-left: 0;
        }
    }
}

.menuLanding .item .link {
    font-size: 14px;
    line-height: 16px;
}
