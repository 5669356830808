.contacts {
    max-width: 600px;

    .icons {
        display: grid;
        grid-template-columns: calc(1.5 * var(--t-level3)) 1fr;
        gap: 10px;
        align-items: center;
        margin-bottom: 20px;
        font-weight: var(--f-normal);
        font-size: 14px;
        line-height: 20px;

        .blue a {
            color: var(--c-blue);
            text-decoration: none;
        }

        svg {
            width: 18px;
            height: auto;
            color: var(--c-blue);
        }

        .address {
            color: var(--c-grey3-80);

            &::first-line {
                color: var(--c-grey3);
            }
        }
    }

    .button {
        margin-bottom: 47px;
    }

    .text {
        margin-bottom: 20px;
        font-weight: var(--f-thiner);
        font-size: var(--t-level3);
    }

    .requisites {
        display: grid;
        grid-template-columns: 5ch 1fr;
        gap: 10px;
        font-weight: var(--f-normal);
        font-size: 14px;
        line-height: 20px;

        @media (--small-until) {
            display: flex;
            flex-direction: column;
        }

        div:nth-of-type(2n - 1) {
            font-weight: var(--f-bold);
        }
    }

    .fromSecond {
        grid-column: 2/-1;
    }
}
