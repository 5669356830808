@import url(@webapp/common/assets/styles/vars.css);
@import url(reset.css);

* {
    outline: none;
}

a {
    text-decoration: none;
}

body {
    box-sizing: border-box;
    min-height: 100vh;
    background-color: var(--c-white);
    color: var(--c-black);
    font-weight: var(--f-normal);
    font-size: 10px;
    font-family: var(--base-font);
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:global {
    .alignLeft {
        text-align: left !important;
    }

    .alignRight {
        text-align: right !important;
    }

    .alignCenter {
        text-align: center !important;
    }

    .alignJustify {
        text-align: justify !important;

        &::after {
            content: '';
            display: inline-block;
            width: 100%;
        }
    }
}
