.wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 210px;
    padding: 80px 80px 100px;
    border-radius: 30px;
    background: linear-gradient(0.25turn, #67bffe, #92fbf4);
    line-height: normal;

    .textWrap {
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: 90px;
        justify-content: space-around;
        width: 46%;
        color: var(--c-white);

        .titleWrap {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .title {
                font-weight: var(--f-bold);
                font-size: 32px;
            }

            .description {
                font-weight: var(--f-bolder);
                font-size: 24px;
            }
        }

        .text {
            font-weight: var(--f-bolder);
            font-size: 18px;
        }
    }

    .btnWrap {
        z-index: 3;
        display: flex;
        flex-direction: column;
        gap: 22px;
        justify-content: flex-end;

        .whiteBtn {
            align-self: end;
            width: max-content;
            background-color: #fff;
            color: #3190ff;
            line-height: normal;
        }
    }

    .mountains {
        position: absolute;
        top: 50px;
        z-index: 1;
        width: auto;
        height: auto;
    }

    .lines {
        position: absolute;
        right: 0;
        bottom: 100px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: 108px;
        width: 45%;

        .line {
            height: 1px;
            background: linear-gradient(0.75turn, var(--c-white), transparent);
        }
    }
}

@media (--large-only) {
    .wrapper {
        margin: 100px 70px;
        padding: 80px 70px;

        .textWrap {
            gap: 80px;

            .titleWrap {
                .title {
                    font-size: 25px;
                }

                .description {
                    font-size: 20px;
                }
            }

            .text {
                font-size: 15px;
            }
        }

        .btnWrap {
            gap: 37px;
        }

        .mountains {
            top: auto;
        }

        .lines {
            bottom: 80px;
            gap: 80px;
            width: 50%;
        }
    }
}

@media (--medium-only) {
    .wrapper {
        margin: 40px 20px;
        padding: 40px 30px;

        .textWrap {
            gap: 30px;
            width: 60%;

            .titleWrap {
                .title {
                    font-size: 25px;
                }

                .description {
                    font-size: 20px;
                }
            }

            .text {
                font-size: 15px;
            }
        }

        .btnWrap {
            gap: 22px;
        }

        .mountains {
            top: auto;
        }

        .lines {
            bottom: 38px;
            gap: 60px;
            width: 50%;
        }
    }
}

@media (--medium-until) {
    .wrapper {
        flex-direction: column;
        gap: 45px;
        margin: 60px 10px 100px;
        padding: 30px 20px;
        background: linear-gradient(0.5turn, #67bffe 70%, #92fbf4);

        .textWrap {
            gap: 25px;
            width: 100%;

            .titleWrap {
                .title {
                    font-size: 20px;
                }

                .description {
                    font-size: 16px;
                }
            }

            .text {
                font-size: 14px;
                line-height: 16px;
            }
        }

        .btnWrap {
            gap: 22px;

            .whiteBtn {
                width: 70%;
            }
        }

        .mountains {
            top: auto;
            right: 0;
            bottom: 0;
            width: 468px;
            height: 222px;
        }

        .lines {
            bottom: 38px;
            gap: 38px;
            width: 100%;
        }
    }
}
