.offer {
    & h1 {
        margin-bottom: 1em;
    }

    .subheader {
        display: flex;
        justify-content: space-between;
    }

    .content {
        margin-bottom: 4em;
        font-size: var(--t-level4);
    }

    & iframe {
        width: 100%;
    }

    .htmlWrapper {
        max-width: 90%;
    }
}

:global {
    .docHTML {
        padding: 0 10px;

        .date {
            display: flex;
            justify-content: space-between;
        }

        & p {
            box-sizing: border-box;
            text-align: justify;
            overflow-wrap: break-word;
        }

        .table-wrap {
            border-top: 1px solid var(--c-black);

            & p {
                margin: 0;
                padding: 10px;
                border-right: 1px solid var(--c-black);
                border-bottom: 1px solid var(--c-black);
                text-align: left;

                &:first-child {
                    border-left: 1px solid var(--c-black);
                }
            }
        }

        & table {
            display: block;
            overflow: auto;

            & td {
                border: 1px solid var(--c-black);
            }

            & p {
                width: 100%;
                margin-bottom: 10px !important;
                padding: 0 15px;
            }
        }
    }
}
