.connectionSteps {
    .steps {
        margin-left: 15px;
        border-left: 3px dashed var(--c-blue);

        .step {
            display: grid;
            grid-template-columns: 40px minmax(auto, 700px);
            align-items: start;
            margin-left: -21px;
            list-style: none;
            font-weight: var(--f-normal);

            &::before {
                display: none;
            }

            &:last-child {
                margin-top: 10px;

                svg {
                    margin-bottom: -10px;
                }
            }

            svg {
                width: 100%;
                margin-top: -5px;
                background: var(--c-white);
                color: var(--c-blue);
            }

            a {
                color: var(--c-blue);
                text-decoration: none;
            }
        }

        .step:last-child p {
            margin-bottom: 0;
        }
    }
}
