.trust {
    .title {
        margin-bottom: 45px;
        font-weight: var(--f-bold);
        font-size: 32px;
        text-align: center;
    }

    .companies {
        position: relative;
        overflow: hidden;
        width: 100%;
        margin-bottom: 200px;
    }

    .list {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: fit-content;
        margin: 0;
        cursor: grabbing;
        will-change: transform;
    }

    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        height: 160px;
        margin-bottom: 20px;
        border-radius: 20px;
        background: #f7f7f7;
    }

    .itemBox {
        width: 300px;

        & > div:last-child {
            margin-bottom: 0;
        }
    }

    .logo {
        width: 55%;
        height: 55%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &:not(:hover) {
            opacity: 0.6;
            filter: grayscale(1);
        }
    }
}

@media (--large-only) {
    .trust {
        .title {
            margin-bottom: 30px;
            font-size: 24px;
        }

        .companies {
            margin-bottom: 150px;
        }

        .list {
            gap: 18px;
        }

        .item {
            height: 135px;
            margin-bottom: 18px;
            border-radius: 26px;
        }

        .itemBox {
            width: 254px;

            & > div:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media (--medium-only) {
    .trust {
        .title {
            margin-bottom: 22px;
            font-size: 24px;
        }

        .companies {
            margin-bottom: 120px;
        }

        .list {
            gap: 10px;
        }

        .item {
            height: 74px;
            margin-bottom: 10px;
            border-radius: 10px;
        }

        .itemBox {
            width: 138px;

            & > div:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media (--medium-until) {
    .trust {
        .title {
            margin-bottom: 18px;
            font-size: 18px;
        }

        .companies {
            margin-bottom: 120px;
        }

        .list {
            gap: 5px;
        }

        .item {
            height: 80px;
            margin-bottom: 5px;
            border-radius: 6px;
        }

        .itemBox {
            width: 120px;

            & > div:last-child {
                margin-bottom: 0;
            }
        }

        .logo {
            width: 70%;
            height: 70%;

            &:not(:hover) {
                opacity: 1;
                filter: grayscale(0);
            }
        }
    }
}
