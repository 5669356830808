.editableSurveyTemplates {
    position: relative;
    margin: 200px 210px 0;
    line-height: normal;

    .title {
        margin-bottom: 20px;
        font-weight: var(--f-bold);
        font-size: 32px;
    }

    .description {
        width: 60%;
        margin-bottom: 50px;
        font-size: 24px;
    }

    .blocks {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }

    .block {
        padding: 50px 40px;
        border-radius: 30px;
        color: var(--c-white);
    }

    .gradient1 {
        background: linear-gradient(305deg, #69bffe 6.01%, #7ad7fa 84.09%);
    }

    .gradient2 {
        background: linear-gradient(6deg, #7bd7fa 2.56%, #6abffe 95.07%);
    }

    .gradient3 {
        background: linear-gradient(180deg, #7bd7fa 0%, #6bbffe 100%);
    }

    .blockTitle {
        margin-bottom: 20px;
        font-weight: var(--f-bold);
        font-size: 24px;
    }

    .points {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .point {
        display: flex;
        gap: 10px;
        color: var(--c-white);
        font-size: 16px;
        text-decoration: none;
    }

    .point:hover {
        font-weight: var(--f-bold);
    }

    .point:hover .arrow {
        transform: rotate(90deg);
    }

    .arrow {
        flex-shrink: 0;
    }

    .button {
        margin: 40px 0 100px;
    }

    .exampleBlock {
        position: relative;
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 200px;
    }

    .videoBlock {
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: space-between;
    }

    .text {
        width: 80%;
        font-size: 24px;
        line-height: normal;
    }

    .icon {
        max-height: 700px;
    }

    .bubble {
        position: absolute;
        bottom: -150px;
        left: -210px;
        z-index: -1;
        width: 90%;
    }

    .bubbleRight {
        position: absolute;
        top: -390px;
        right: -210px;
        z-index: -1;
    }
}

@media (--large-only) {
    .editableSurveyTemplates {
        margin: 100px 70px 0;

        .title {
            font-size: 24px;
        }

        .description {
            margin-bottom: 40px;
            font-size: 20px;
        }

        .blocks {
            gap: 16px;
        }

        .block {
            padding: 40px 30px;
        }

        .blockTitle {
            font-size: 20px;
        }

        .points {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .point {
            display: flex;
            gap: 10px;
            font-size: 14px;
        }

        .button {
            margin: 20px 0 100px;
        }

        .exampleBlock {
            gap: 130px;
        }

        .text {
            width: 80%;
            font-size: 20px;
        }

        .icon {
            max-height: 560px;
        }

        .bubble {
            right: 300px;
            left: auto;
        }

        .bubbleRight {
            display: none;
        }
    }
}

@media (--medium-only) {
    .editableSurveyTemplates {
        margin: 100px 20px;

        .title {
            font-size: 24px;
        }

        .description {
            width: 100%;
            margin-bottom: 20px;
            font-size: 20px;
        }

        .blocks {
            gap: 16px;
            overflow-x: auto;
        }

        .block {
            padding: 40px 30px;
        }

        .blockTitle {
            font-size: 20px;
        }

        .points {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .point {
            display: flex;
            gap: 10px;
            font-size: 14px;
        }

        .button {
            margin: 20px 0 90px;
        }

        .exampleBlock {
            grid-template-columns: 1fr;
        }

        .text {
            width: 90%;
            font-size: 20px;
        }

        .icon {
            display: none;
        }

        .bubble {
            right: 0;
            bottom: -80px;
            left: auto;
        }

        .bubbleRight {
            display: none;
        }
    }
}

@media (--medium-until) {
    .editableSurveyTemplates {
        margin: 100px 10px;

        .title {
            font-size: 18px;
        }

        .description {
            width: 100%;
            margin-bottom: 20px;
            font-size: 14px;
        }

        .blocks {
            gap: 6px;
            overflow-x: auto;
        }

        .block {
            min-width: 250px;
            padding: 40px 30px;
        }

        .blockTitle {
            font-size: 16px;
        }

        .points {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .point {
            display: flex;
            gap: 5px;
            font-size: 14px;
        }

        .button {
            display: flex;
            width: 100% !important;
            margin: 10px 0 60px;
        }

        .videoBlock {
            flex-direction: column;
            gap: 15px;
        }

        .exampleBlock {
            grid-template-columns: 1fr;
        }

        .text {
            width: 100%;
            font-size: 14px;
        }

        .icon {
            display: none;
        }

        .bubble {
            display: none;
        }

        .bubbleRight {
            display: none;
        }
    }
}
