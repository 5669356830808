.staticPage {
    @media (--medium) {
        display: flex;
    }

    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 20px;
    }

    p,
    ol,
    ul,
    ol > li,
    ul > li {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 20px;
    }

    ul > li,
    ol > li {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 10px;
        list-style: inside;
        fill: var(--c-blue);

        a,
        a:hover,
        a:active,
        a:visited {
            color: inherit;
            font-size: inherit;
            text-decoration: none;
        }
    }

    ul > li {
        &::before {
            content: '';
            display: block;
            flex: 0 0 auto;
            width: clamp(10px, calc(8px + 1vw), 20px);
            height: 2ch;
            background: url(components/ui/icons/right-arrow.svg) no-repeat 0 50%;
            background-size: clamp(10px, calc(8px + 1vw), 20px);
            fill: var(--c-blue);
        }
    }
}

.section {
    width: 100%;
    max-width: calc(var(--content-grid-column) * 9);
    color: var(--c-grey3);

    .title {
        padding: 3px 0 20px;
        font-weight: var(--f-bold);
        font-size: 36px;
        line-height: 44px;

        @media (--medium-until) {
            margin-top: 20px;
            font-size: 32px;
            line-height: 39px;
        }

        @media (max-width: 600px) {
            font-size: 24px;
            line-height: 29px;
        }
    }

    .description {
        padding: 20px 0;
        font-weight: var(--f-bold);
        font-size: 24px;
        line-height: 44px;

        @media (--medium-until) {
            padding: 10px 0;
        }

        @media (max-width: 600px) {
            font-size: 14px;
            line-height: 26px;
        }
    }
}
