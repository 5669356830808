.switch {
    @mixin flexHList 5px;
    @mixin flexVCenter;
    width: max-content;
    max-width: 100%;
    cursor: pointer;
    user-select: none;

    &.disabled .control,
    &.disabled .text {
        opacity: 0.3;
        cursor: initial;
        pointer-events: none;
    }

    .control {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        box-sizing: border-box;
        width: 22px;
        height: 14px;
        border: 1px solid;
        border-radius: 40px;
        transition: bolder-color ease-in-out 0.1s;

        &::before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 40px;
            background-color: currentColor;
            transition: all ease-in-out 0.1s;
            transform: translateX(2px);
        }

        &:not(.checked) {
            color: var(--c-greyC);
        }

        &.checked {
            color: var(--c-blue);

            &::before {
                transform: translateX(10px);
            }
        }
    }

    .label {
        @mixin flexHList 0.25em;
        font-weight: var(--f-normal);
    }
}
