.privacyPopup {
    position: fixed;
    right: 3vw;
    bottom: 3vw;
    z-index: 1000;
    max-width: 320px;
    border-radius: clamp(14px, 2vw, 25px);
    background-color: var(--c-white);
    box-shadow: 0 2vw 8vw var(--c-blue6_a_3d);
    font-size: 14px;
    line-height: 1.4;
    padding: clamp(14px, 2vw, 25px);

    @media (--component-break1) {
        left: 3vw;
        max-width: 100%;
    }

    .content {
        padding-bottom: 20px;
        margin: 0;
        font-weight: var(--f-normal);
    }
}
