.apiIntegration {
    margin: 200px 210px;
    border-radius: 30px;
    background: linear-gradient(101deg, #68bffe 19.06%, #8cf2f6 98.32%);
    line-height: normal;

    .container {
        display: flex;
        justify-content: space-between;
        overflow-x: hidden;
        padding: 80px 80px 100px;
    }

    .textWrap {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 46%;
        color: var(--c-white);
    }

    .title {
        margin-bottom: 10px;
        font-weight: var(--f-bold);
        font-size: 32px;
    }

    .description {
        margin-bottom: 90px;
        font-weight: var(--f-bolder);
        font-size: 24px;
    }

    .text {
        font-weight: var(--f-bolder);
        font-size: 16px;
        line-height: 20px;
    }

    .blockWithImage {
        position: relative;
        display: flex;
    }

    .apiIntegrationBtn {
        align-self: end;
    }

    .image {
        position: absolute;
        right: -80px;
        bottom: -10px;
    }
}

@media (--large-only) {
    .apiIntegration {
        margin: 100px 70px;

        .container {
            display: flex;
            justify-content: space-between;
            padding: 60px 70px;
        }

        .textWrap {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 55%;
            color: var(--c-white);
        }

        .title {
            margin-bottom: 20px;
            font-size: 24px;
        }

        .description {
            margin-bottom: 30px;
            font-size: 20px;
        }

        .blockWithImage {
            position: relative;
            display: flex;
        }

        .apiIntegrationBtn {
            align-self: end;
        }

        .image {
            position: absolute;
            right: -110px;
            bottom: -40px;
            height: 330px;
        }
    }
}

@media (--medium-only) {
    .apiIntegration {
        margin: 100px 20px;

        .container {
            display: flex;
            flex-direction: column;
            padding: 40px 30px;
        }

        .textWrap {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
            color: var(--c-white);
        }

        .title {
            margin-bottom: 20px;
            font-size: 24px;
        }

        .description {
            margin-bottom: 30px;
            font-size: 20px;
        }

        .text {
            margin-bottom: 60px;
        }

        .blockWithImage {
            position: relative;
            display: flex;
        }

        .apiIntegrationBtn {
            align-self: end;
        }

        .image {
            display: none;
        }
    }
}

@media (--medium-until) {
    .apiIntegration {
        margin: 100px 10px;

        .container {
            flex-direction: column;
            padding: 30px 20px;
        }

        .textWrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            color: var(--c-white);
        }

        .title {
            margin-bottom: 20px;
            font-size: 20px;
        }

        .description {
            margin-bottom: 30px;
            font-size: 16px;
        }

        .text {
            margin-bottom: 20px;
            font-size: 14px;
        }

        .blockWithImage {
            position: relative;
            display: flex;
        }

        .apiIntegrationBtn {
            align-self: end;
        }

        .image {
            position: relative;
            right: -20px;
            bottom: 20px;
            display: block;
        }
    }
}
