.advs {
    .advsTitle {
        font-weight: var(--f-bold);
    }

    .titleBig {
        margin-bottom: 20px;
        font-weight: var(--f-bold);

        &::before {
            display: none;
        }
    }

    ul li:hover {
        color: var(--c-blue);
    }
}
