:root {
    /* stylelint-disable */
    --base-font: 'Montserrat',
    -apple-system,
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
    /* stylelint-enable */
    --f-thiner: 300;
    --f-normal: 400;
    --f-bolder: 500;
    --f-bolder2: 600;
    --f-bold: 700;
    --f-bold2: 800;
    --t-level1: clamp(16px, 14px + 2vw, 24px);
    --t-level2: clamp(12px, 12px + 2vw, 22px);
    --t-level3: clamp(10px, 10px + 2vw, 17px);
    --t-level4: clamp(8px, 8px + 2vw, 16px);
    --t-level5: clamp(8px, 8px + 2vw, 14px);
    --t-level5_5: clamp(8px, 8px + 1.5vw, 12px);
    --t-level6: clamp(6px, 6px + 1vw, 10px);
    --p-outer: 3vw;
    --p-inner: 2vw;
    --b-border-radius: 2rem;
    --items-gap: 1ch;
    --list-gap: 10px;
    --blocks-gap: 3ch;
    --grid-gap: 2ch;
    --i-focus: 2px solid var(--c-border-focus);
    --border-width: 0.15em;
    --border-width-thin: 0.075em;
    --content-grid-column: 102px;
    --content-max-width: calc(var(--content-grid-column) * 12);
    --header-height: 70px;
}
