:root {
    --t-level1: 32px;
    --t-level2: 24px;
    --t-level2_5: calc(14px + 1vw);
    --t-level3: 18px;
    --t-level4: 14px;
    --t-level5: 12px;

    @media (--medium-until) {
        --t-level4: calc(9px + 1vw);
        --t-level5: calc(10px + 1vw);
    }
}

h1,
h2,
h3,
h4 {
    font-weight: var(--f-bold);
}

h1 {
    font-size: var(--t-level1);
    line-height: 40px;
}

h2 {
    font-size: var(--t-level2);
    line-height: 1.5;
}

h3 {
    font-weight: var(--f-normal);
    font-size: 18px;
    line-height: 22px;
}

h4 {
    font-weight: var(--f-normal);
    font-size: var(--t-level4);
    line-height: 1em;
}

.aside {
    @media (--medium-until) {
        margin-bottom: 5vw;
        padding: 13px 30px;
        background: var(--c-border);
    }

    .menu:not(.mobileVisible) {
        margin-left: 3vw;

        @media (--medium-until) {
            display: none;
        }
    }

    .collapse {
        cursor: pointer;

        @media (--medium) {
            display: none;
        }

        .link {
            position: relative;
            display: flex;
            gap: 2ch;
            align-items: center;
            font-weight: var(--f-normal);
            font-size: 18px;
            line-height: 22px;

            svg {
                width: 1.5ch;
                transform: rotate(180deg);
            }
        }
    }
}

.page {
    min-height: 100vh;

    .content {
        flex-grow: 1;

        &.contentPage {
            margin-top: 40px;
        }

        &.withMenu {
            display: grid;
            grid-template-columns: max-content 1fr;
            grid-gap: 60px;

            @media (--medium-until) {
                display: flex;
                flex-direction: column;
                grid-gap: 0;
            }
        }
    }
}
