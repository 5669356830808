/* .help {} */

.search {
    @mixin boxShadow;
    display: flex;
    max-width: 400px;
    margin: 30px;
    padding: 8px 20px;
    border-radius: var(--b-border-radius);
    background: var(--c-white);
    column-gap: 1em;

    svg {
        flex-shrink: 0;
        width: 2em;
        opacity: 0.3;
    }

    input {
        display: flex;
        flex: 1 1 auto;
        border: none;
    }
}
