.avito {
    background-image: url(assets/logos/avito-logo.png);
}

.alphaEnsurance {
    background-image: url(assets/logos/alfa-ensurance.png);
}

.alphaBank {
    background-image: url(assets/logos/alpha-bank.png);
}

.anderson {
    background-image: url(assets/logos/anderson.png);
}

.beeline {
    background-image: url(assets/logos/beeline.png);
}

.cy22 {
    background-image: url(assets/logos/cy22.png);
}

.kreditEvropaBank {
    background-image: url(assets/logos/kredit-evropa-bank.png);
}

.linzmaster {
    background-image: url(assets/logos/linzmaster.png);
}

.mercedes {
    background-image: url(assets/logos/mercedes.png);
}

.metroCC {
    background-image: url(assets/logos/metro-cc.png);
}

.mtsBank {
    background-image: url(assets/logos/mts-bank.png);
}

.mumu {
    background-image: url(assets/logos/mumu.png);
}

.openBank {
    background-image: url(assets/logos/openbank.png);
}

.ostin {
    background-image: url(assets/logos/ostin.png);
}

.puma {
    background-image: url(assets/logos/puma.png);
}

.rambler {
    background-image: url(assets/logos/rambler.png);
}

.raoEes {
    background-image: url(assets/logos/rao-ees.png);
}

.renessans {
    background-image: url(assets/logos/renessans.png);
}

.rosgonki {
    background-image: url(assets/logos/rosgonki.png);
}

.rozaHutor {
    background-image: url(assets/logos/roza-hutor.png);
}

.skyeng {
    background-image: url(assets/logos/skyeng.png);
}

.swagelok {
    background-image: url(assets/logos/swagelok.png);
}

.ticketland {
    background-image: url(assets/logos/ticketland.png);
}

.vtb {
    background-image: url(assets/logos/vtb.png);
}
