@keyframes TTMessageMoveUpIn {
    0% {
        opacity: 0;
        transform: translateY(-100%);
        transform-origin: 0 0;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
        transform-origin: 0 0;
    }
}

@keyframes TTMessageMoveUpOut {
    0% {
        opacity: 1;
        transform: translateY(0);
        transform-origin: 0 0;
    }

    100% {
        opacity: 0;
        transform: translateY(-100%);
        transform-origin: 0 0;
    }
}

@keyframes TTMessageMoveOut {
    0% {
        max-height: 150px;
        padding: 8px;
        opacity: 1;
    }

    100% {
        max-height: 0;
        padding: 0;
        opacity: 0;
    }
}

.move-up-appear,
.move-up-enter,
.move-up-leave {
    animation-duration: 0.2s;
    animation-play-state: paused;
    animation-fill-mode: both;
}

.move-up-appear.move-up-appear-active,
.move-up-enter.move-up-enter-active {
    animation-name: TTMessageMoveUpIn;
    animation-play-state: running;
}

.move-up-leave.move-up-leave-active {
    pointer-events: none;
    animation-name: TTMessageMoveUpOut;
    animation-play-state: running;
}

.move-up-appear,
.move-up-enter {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-up-leave {
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.tt-message {
    position: fixed;
    top: 16px;
    left: 0;
    z-index: 10000;
    width: 100%;
    pointer-events: none;

    &-notice {
        padding: 8px;
        text-align: center;

        &:first-child {
            margin-top: -8px;
        }
    }

    &-notice-content {
        @mixin popupShadow;
        display: inline-block;
        padding: 10px;
        border-radius: 6px;
        background: var(--c-white);
        pointer-events: all;
        box-shadow: 1px 1px 7px 3px rgba(0, 0, 0, 0.25);
        background-color: var(--c-border);
    }

    &-success .tt-icon {
        color: var(--c-msg-success);
    }

    &-error .tt-icon {
        color: var(--c-red);
    }

    &-info .tt-icon,
    &-loading .tt-icon {
        color: var(--c-blue);
    }

    .tt-icon {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
        font-size: 1.14285714em;

        svg {
            display: block;
            height: 1em;
        }
    }

    &-notice.move-up-leave.move-up-leave-active {
        animation-name: TTMessageMoveOut;
        animation-duration: 0.3s;
    }
}
