.plan {
    @mixin boxShadow;
    width: 295px;
    padding-bottom: 20px;
    border-radius: 23px;

    @media (--small-until) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &.closed {
            overflow: hidden;
            height: 44px;
            margin-bottom: 20px;

            svg {
                transform: rotate(0) !important;
            }
        }
    }

    .planHeader {
        @mixin boxShadow;
        @mixin barGradient;
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        color: var(--c-white);
        font-weight: var(--f-bold);
        font-size: 18px;
        line-height: normal;
        text-align: center;
        white-space: break-spaces;

        @media (--small-until) {
            @mixin boxShadow;
            z-index: 2;
            grid-row: 1/2;
            grid-column: 1/2;
            width: min-content;
            padding: 0 17px;
            border-radius: 15px;
            font-size: 12px;
            line-height: 130%;
            text-align: left;
        }
    }

    .addButton {
        @mixin boxShadow;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 74px 20px 20px;
        padding: 10px;
        border-radius: 50px;
        background: var(--c-white);
        color: var(--c-grey3);
        font-weight: var(--f-bold);
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        text-decoration: none;

        @media (--medium-until) {
            width: unset;
            margin: 80px 25px 0;
        }
    }

    .planActions {
        padding-bottom: 20px;
        background: var(--c-border);

        @media (--small-until) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-row: 1/-1;
            grid-column: 1/-1;
            padding-bottom: 0;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
        }

        .price {
            padding: 20px 0;
            background: var(--c-border);
            font-weight: var(--f-bold);
            font-size: 24px;
            text-align: center;

            @media (--medium-until) {
                padding: 22px 0;
            }

            @media (--small-until) {
                display: flex;
                flex-direction: column;
                grid-column: 2/3;
                align-content: center;
                justify-content: space-between;
                align-items: center;
                padding: 22px 13%;
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
                font-size: 16px;
                line-height: 33px;
                cursor: pointer;
            }

            .hint {
                color: var(--c-grey3);
                font-weight: 300;
                font-style: normal;
                font-size: 14px;
                line-height: 20px;

                @media (--small-until) {
                    line-height: 0.5em;
                }
            }
        }

        .addButton {
            margin: 0 20px;
            color: var(--c-grey3);
            font-weight: var(--f-bold);
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            text-decoration: none;

            @media (--medium-until) {
                margin: 0 25px;
            }

            @media (--small-until) {
                grid-column: 1/-1;
                align-self: center;
                margin: 2ch;
            }
        }
    }

    .planItem {
        display: flex;
        gap: 10px;
        align-items: center;
        box-sizing: border-box;
        height: 45px;
        padding: 10px 20px;
        border-top: 1px solid var(--c-border);
        font-weight: var(--f-normal);
        font-size: var(--t-level4);

        @media (--small-until) {
            grid-column: 1/-1;
        }

        &.customHeight {
            height: 100px;
            padding-top: 23px;
        }

        &.green svg {
            color: var(--c-green);
        }

        &.red svg {
            color: var(--c-red);
        }

        svg {
            display: flex;
            flex-shrink: 0;
            width: 16px;
            height: 16px;
            color: var(--c-blue);
        }

        &.selectedItem {
            border-top: 1px solid var(--c-blue-a1a);
            background: var(--c-border);
        }
    }
}
