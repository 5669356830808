:global(.uiButton) {
    display: inline-flex !important;
    width: fit-content !important;
}

.contentPage {
    max-width: var(--content-max-width);
    margin: 0 3vw;

    @media (--medium) {
        display: flex;
        justify-content: center;
        width: 85vw;
        margin: 0 auto;
    }

    &.withMenu {
        width: auto;
    }

    & > section > h1,
    & > section > h2,
    & > section > h3 {
        text-align: center;
    }
}

.cms {
    position: relative;
    width: 100%;
    min-height: 100%;
    padding-top: 20px;
    padding-bottom: 34px;

    table {
        width: 100% !important;
    }

    a {
        color: var(--c-link);
        text-decoration: none;
    }

    p,
    ol,
    ul,
    ol > li,
    ul > li {
        margin-bottom: 1.5em;
        font-size: clamp(12px, calc(8px + 1vw), 20px);
        line-height: 1.5;
    }

    hr {
        clear: both;
        overflow: hidden;
        width: 100% !important;
        height: 1px !important;
        margin-top: 0;
        margin-bottom: 30px;
        border: 0;
        background-color: var(--c-white-bg) !important;
    }

    h1,
    h2,
    h3,
    h4 {
        margin-bottom: 1em;
    }

    blockquote {
        margin-right: 0;
        margin-left: 0;
        padding-right: 1em;
        padding-left: 1em;
        border-left: 3px solid var(--c-blue);

        p {
            padding: 0 1ch;
        }
    }

    ul > li,
    ol > li {
        display: flex;
        gap: 3ch;
        align-items: center;
        list-style: inside;
        font-size: clamp(12px, calc(8px + 1vw), 20px);
        line-height: 1.5;
        fill: var(--c-blue);
    }

    ul > li {
        &::before {
            content: '';
            display: block;
            flex: 0 0 auto;
            width: clamp(10px, calc(8px + 1vw), 20px);
            height: 2ch;
            background: url(components/ui/icons/right-arrow.svg) no-repeat 0 50%;
            background-size: clamp(10px, calc(8px + 1vw), 20px);
            fill: var(--c-blue);
        }
    }

    ol {
        counter-reset: list;
    }

    ol > li {
        counter-increment: list;

        &::before {
            content: counter(list);
            display: flex;
            flex: 0 0 auto;
            align-content: center;
            align-items: center;
            width: clamp(10px, calc(8px + 1vw), 20px);
            height: 2ch;
            color: var(--c-blue);
            font-weight: var(--f-bold);
            font-size: 2ch;
            line-height: 1.5;
        }
    }
}
