.possibilities {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 150px;
    margin: 0 210px;
    line-height: normal;

    .section {
        position: relative;
    }

    .title {
        width: 34%;
        margin-bottom: 35px;
        font-weight: var(--f-bold);
        font-size: 32px;
    }

    .blocksWrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .smallBlocks {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .block {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;
        padding: 30px;
        border-radius: 30px;
    }

    .blue {
        position: relative;
        justify-content: flex-end;
        background: linear-gradient(0.25turn, #6ac1ff, #79d6fc);
    }

    .white {
        border: 2px solid #f1f1f1;
    }

    .grey {
        background-color: #f9f9f9;
    }

    .icon {
        position: absolute;
        top: 0;
    }

    .left {
        left: 0;
    }

    .right {
        right: 0;
    }

    .blackText {
        font-weight: var(--f-bold);
        font-size: 16px;
        line-height: 1.2;
    }

    .whiteText {
        z-index: 2;
        color: #fff;
        font-weight: var(--f-bold);
        font-size: 16px;
    }

    .whiteTextSmall {
        margin-top: 5px;
        font-weight: var(--f-bolder);
        font-size: 14px;
    }

    .bigBlock {
        display: flex;
        flex-direction: column;
        padding: 50px 40px;
        border-radius: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        color: var(--c-white);
    }

    .background1 {
        background-image: url(assets/main-page/background1.png);
    }

    .background2 {
        background-image: url(assets/main-page/background2.png);
    }

    .background3 {
        background-image: url(assets/main-page/background3.png);
    }

    .background4 {
        background-image: url(assets/main-page/background4.png);
    }

    .bigBlockTitle {
        margin-bottom: 15px;
        font-weight: var(--f-bolder);
        font-size: 24px;
    }

    .bigBlockText {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 26px;
        font-weight: var(--f-bolder);
        font-size: 16px;
    }

    .link {
        width: fit-content;
        padding: 12px 35px;
        border: 1px solid var(--c-white);
        border-radius: 100px;
        color: var(--c-white);
        font-weight: var(--f-bold);
        font-size: 16px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
    }

    .bubbleLeft,
    .bubbleLeftBottom {
        position: absolute;
        top: 0;
        left: -210px;
        z-index: -1;
    }

    .bubbleRight {
        position: absolute;
        top: 0;
        right: -210px;
        z-index: -1;
    }

    .bubbleRightAnalyzeData {
        position: absolute;
        right: -210px;
        bottom: -128px;
        z-index: -1;
    }

    .mobileBlocks {
        display: none;
    }

    .desktopBlocks {
        display: flex;
    }
}

@media (--large-only) {
    .possibilities {
        margin: 0 70px;

        .title {
            width: 50%;
            margin-bottom: 30px;
            font-size: 24px;
        }

        .blocksWrap {
            gap: 10px;
        }

        .smallBlocks {
            gap: 10px;
        }

        .block {
            gap: 20px;
            padding: 30px 20px;
        }

        .blackText {
            font-size: 14px;
        }

        .whiteText {
            font-size: 14px;
        }

        .whiteTextSmall {
            font-size: 12px;
        }

        .bigBlock {
            padding: 50px 30px;
        }

        .bigBlockTitle {
            margin-bottom: 35px;
            font-size: 20px;
        }

        .bigBlockText {
            margin-bottom: 50px;
            font-size: 14px;
        }

        .bubbleRight {
            right: -70px;
            overflow-x: hidden;
        }

        .bubbleRightAnalyzeData {
            right: -70px;
            overflow-x: hidden;
            width: 50%;
        }

        .bubbleLeft,
        .bubbleLeftBottom {
            left: -160px;
        }
    }
}

@media (--medium-only) {
    .possibilities {
        margin: 0 20px;

        .title {
            width: 50%;
            margin-bottom: 30px;
            font-size: 24px;
        }

        .blocksWrap {
            grid-template-columns: 1fr;
            gap: 10px;
        }

        .smallBlocks {
            gap: 10px;
        }

        .block {
            gap: 35px;
            padding: 30px 20px;
        }

        .blackText {
            font-size: 14px;
        }

        .whiteText {
            font-size: 14px;
        }

        .whiteTextSmall {
            font-size: 12px;
        }

        .bigBlock {
            padding: 50px 30px;
        }

        .bigBlockTitle {
            margin-bottom: 35px;
            font-size: 20px;
        }

        .bigBlockText {
            margin-bottom: 50px;
            font-size: 14px;
        }

        .bubbleLeft,
        .bubbleRight,
        .bubbleRightAnalyzeData,
        .bubbleLeftBottom {
            display: none;
        }
    }
}

@media (--medium-until) {
    .possibilities {
        display: flex;
        flex-direction: column;
        margin: 0 10px;

        .title {
            width: 100%;
            margin-bottom: 20px;
            font-size: 18px;
        }

        .blocksWrap {
            display: flex;
            flex-direction: column;
            gap: 6px;
        }

        .smallBlocks {
            display: flex;
            flex-direction: column;
            gap: 6px;
            order: 2;
        }

        .mobileBlocks {
            display: flex;
            gap: 6px;
        }

        .desktopBlocks {
            display: none;
        }

        .width {
            width: 50%;
        }

        .order {
            order: -1;
        }

        .order1 {
            order: 1;
        }

        .block {
            gap: 35px;
            min-height: 130px;
            padding: 30px 15px;
            border-radius: 25px;
        }

        .icon {
            position: absolute;
            top: 0;
            left: 0;
        }

        .blackText {
            font-size: 14px;
        }

        .whiteText {
            font-size: 14px;
        }

        .whiteTextSmall {
            font-size: 10px;
        }

        .bigBlock {
            padding: 30px 20px;
        }

        .bigBlockTitle {
            margin-bottom: 15px;
            font-weight: var(--f-bolder);
            font-size: 16px;

            .br {
                display: none;
            }
        }

        .bigBlockText {
            margin-bottom: 26px;
            font-weight: var(--f-bolder);
            font-size: 14px;
        }

        .link {
            width: auto;
            padding: 12px 30px;
            font-size: 14px;
        }

        .bubbleLeft,
        .bubbleRight,
        .bubbleRightAnalyzeData,
        .bubbleLeftBottom {
            display: none;
        }
    }
}
