.distributionSurvey {
    display: flex;
    gap: 20px;
    margin: 200px 210px 0;
    line-height: normal;

    .bigBlock {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: 50px 40px;
        border-radius: 30px;
        background: linear-gradient(180deg, #69bffe 0%, #8bf0f6 100%);
        color: var(--c-white);
    }

    .bigBlockText {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .bigBlockTitle {
        font-weight: var(--f-bold);
        font-size: 24px;
    }

    .text {
        width: 85%;
        font-weight: var(--f-bolder);
        font-size: 16px;
    }

    .buttons {
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .distributionSurveyWhiteBtn {
            background-color: #fff;
            color: #3190ff;
        }
    }

    .distributionSurveyBtn {
        width: fit-content;
    }

    .image {
        position: absolute;
        right: 0;
        bottom: 45px;
    }

    .smallBlocks {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .blocksRow {
        display: flex;
        gap: 20px;
    }

    .block {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;
        width: 50%;
        padding: 30px;
        border-radius: 30px;
        font-weight: var(--f-bold);
        font-size: 16px;
    }

    .blockHorizontal {
        flex-direction: row;
        align-items: center;
        width: auto;

        .icon {
            align-self: start;
            width: 74px;
        }
    }

    .white {
        border: 2px solid #f1f1f1;
    }

    .grey {
        background-color: #f9f9f9;
    }

    .blue {
        position: relative;
        justify-content: flex-end;
        background-image: url(assets/main-page/background1.png);
        background-size: cover;
        background-repeat: no-repeat;
        color: var(--c-white);

        .image {
            top: 0;
        }
    }
}

@media (--large-only) {
    .distributionSurvey {
        margin: 150px 70px 0;

        .bigBlock {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            padding: 50px 40px;
            border-radius: 30px;
            background: linear-gradient(180deg, #69bffe 0%, #8bf0f6 100%);
            color: var(--c-white);
        }

        .bigBlockText {
            gap: 30px;
        }

        .bigBlockTitle {
            font-size: 20px;
        }

        .text {
            font-size: 16px;
        }

        .image {
            position: absolute;
            right: 0;
            bottom: 45px;
        }

        .smallBlocks {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .blocksRow {
            display: flex;
            gap: 10px;
        }

        .block {
            padding: 30px 20px;
        }
    }
}

@media (--medium-only) {
    .distributionSurvey {
        flex-direction: column;
        margin: 0 20px;

        .bigBlock {
            gap: 90px;
            width: auto;
            padding: 50px 30px;
        }

        .bigBlockText {
            gap: 30px;
        }

        .bigBlockTitle {
            font-weight: var(--f-bold);
            font-size: 20px;
        }

        .text {
            width: 80%;
            font-weight: var(--f-bolder);
            font-size: 16px;
        }

        .smallBlocks {
            gap: 10px;
        }

        .blocksRow {
            gap: 10px;
        }

        .block {
            padding: 30px 20px;
        }
    }
}

@media (--medium-until) {
    .distributionSurvey {
        display: flex;
        flex-direction: column;
        margin: 0 10px;

        .bigBlockTitle {
            width: 100%;
            font-size: 20px;
        }

        .bigBlockText {
            width: 100%;
            font-size: 14px;
        }

        .bigBlock {
            gap: 50px;
            width: auto;
            padding: 30px 20px;
        }

        .text {
            width: 100%;
            font-size: 16px;
        }

        .buttons {
            margin-bottom: 60px;
        }

        .smallBlocks {
            gap: 10px;
        }

        .blocksRow {
            flex-direction: column;
            gap: 10px;
        }

        .block {
            width: auto;
            min-height: 130px;
            padding: 30px 20px;
        }

        .blockHorizontal {
            flex-direction: column-reverse;
        }

        .distributionSurveyBtn {
            width: auto;
            white-space: normal;
        }

        .imageDiagram {
            bottom: 20px;
            left: -170px;
        }
    }
}
