.paymentVariants {
    max-width: 803px;

    .description {
        margin: 30px 0;
    }

    .adv {
        p:first-of-type {
            font-weight: var(--f-bold);
        }
    }

    .paymentCards {
        display: flex;
        flex-flow: row wrap;
        margin: 30px 0;
        cursor: pointer;

        .paymentCard {
            @mixin boxShadow;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            width: 180px;
            height: 90px;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 0 6px;
            border-radius: 10px;
            opacity: 0.5;
            filter: grayscale(100%);
            transition: opacity 0.15s, filter 0.15s;

            @media (--small-until) {
                width: 140px;
                height: 70px;
                margin-right: 4px;
            }

            img {
                max-width: 100%;
            }

            &:hover {
                @mixin popupShadow;
                opacity: 1;
                filter: none;
            }

            .visa {
                width: 60px;
                height: 38px;
            }

            .masterpass {
                width: 149px;
                height: 40px;
            }

            .alfaclick {
                width: 127px;
                height: 63px;
            }

            .yandex {
                width: 107px;
                height: 58px;
            }

            .webmoney {
                width: 129px;
                height: 45px;
            }

            .cash {
                width: 144px;
                height: 30px;
            }

            .psb1 {
                width: 45px;
                height: 38px;
            }

            .psb2 {
                width: 109px;
                max-width: calc(100% - 45px);
                height: 20px;
            }
        }
    }
}
