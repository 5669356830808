.functions {
    svg {
        width: 1.2em;
    }

    .green svg {
        color: var(--c-green);
    }

    .red svg {
        color: var(--c-red);
    }

    .blue svg {
        color: var(--c-blue);
    }

    .table {
        display: grid;
        grid-auto-rows: 1fr;
        box-sizing: border-box;
        border: 1px solid var(--c-border);
        border-radius: 4px;
        font-weight: var(--f-normal);
        font-size: var(--t-level4);

        .tableGroupHeader {
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            padding-left: min(1vw, 30px);
            background: var(--c-border);
            font-weight: var(--f-bold);
        }

        .row {
            display: grid;
            grid-template-columns: 3fr 1fr 1fr 1fr;
            gap: 1ch;
            align-items: center;
            min-width: 0;
            padding-top: 1em;
            padding-bottom: 1em;
            padding-left: min(1vw, 30px);
            border-bottom: 1px solid var(--c-border);
            text-align: center;
            word-break: break-word;

            @media (--small-until) {
                display: flex;
                flex-wrap: wrap;

                & > div {
                    width: calc(33% - 1ch);
                }

                & > :nth-child(1) {
                    width: 100%;
                    font-weight: var(--f-bolder);
                }
            }

            div:first-child {
                text-align: left;
            }
        }
    }
}

.diffSwitch {
    flex-shrink: 1;
    flex-wrap: wrap;
    word-break: break-word;

    @media (--medium-until) {
        flex-wrap: nowrap;
    }

    & > div {
        flex-wrap: wrap;
        word-break: break-word;
    }
}

.search {
    @mixin boxShadow;
    display: flex;
    max-width: 400px;
    margin-bottom: 0.5em;
    padding: 8px 20px;
    border-radius: var(--b-border-radius);
    background: var(--c-white);
    column-gap: 1em;

    svg {
        flex-shrink: 0;
        width: 2em;
        opacity: 0.3;
    }

    input {
        display: flex;
        flex: 1 1 auto;
        border: none;
    }
}

.tableHeader {
    position: sticky;
    top: 0;
    display: flex;
    gap: 1ch;
    align-items: center;
    min-width: 0;
    padding: 1em 5px;
    background-color: var(--c-white);
    font-size: var(--t-level4);
    text-align: center;

    @media (--medium-until) {
        justify-content: space-between;
    }

    @media (--small-until) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & > span {
            width: calc(33% - 1ch) !important;
        }

        & > :nth-child(1) {
            width: 100% !important;
        }
    }

    & > :nth-child(1) {
        width: 49%;

        @media (--medium-until) {
            width: 100%;
        }
    }

    & > :nth-child(2) {
        width: 16%;

        @media (--medium-until) {
            width: auto;
        }
    }

    & > :nth-child(3) {
        width: 16%;

        @media (--medium-until) {
            width: auto;
        }
    }

    & > :nth-child(4) {
        width: 16%;

        @media (--medium-until) {
            width: auto;
        }
    }

    & > span {
        align-items: center;
        font-weight: bolder;
        font-size: 14px;

        @media (--medium-until) {
            font-size: 13px;
        }
    }

    .blue {
        color: var(--c-blue);
    }
}

.bottomLink {
    padding: 2ch 0;
}
