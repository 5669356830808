.icon {
    position: relative;
    display: inline-block;
    width: 1.5em;

    svg {
        position: absolute;
        bottom: 0;
        display: block;
        width: 1.5em;
        height: 1.5em;
        color: var(--c-blue) !important;
        cursor: pointer;
        transform: translateY(-0.25em);
    }
}

.bubble {
    box-shadow: 0 4px 10px #2e5bff33;
    z-index: 999999;
    max-width: 30ch;
    padding: 1.5em;
    border-radius: var(--b-border-radius);
    background: var(--c-white);
    font-weight: var(--f-normal);
    font-size: var(--t-level5_5);
    line-height: 1.5;
    white-space: initial;
    visibility: hidden;

    &.visible {
        visibility: visible;
    }
}
