.about {
    position: relative;

    .container {
        position: relative;
        box-sizing: border-box;
        margin: 200px 210px;
    }

    .title {
        margin-bottom: 40px;
        font-weight: var(--f-bold);
        font-size: 32px;
        text-align: center;
    }

    .wrap {
        display: grid;
        grid-template-columns: 1.4fr 1fr;
        gap: 20px;

        .blocksWrap {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .blocks {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
                height: 100%;

                .blueBlock {
                    position: relative;
                    box-sizing: border-box;
                    height: 100%;
                    padding: 40px;
                    border-radius: 30px;
                    background: linear-gradient(0.25turn, #7fe0f9, #68bffe);

                    .blueBlockTitle {
                        margin-bottom: 20px;
                        color: var(--c-white);
                        font-weight: var(--f-bold);
                        font-size: 24px;
                    }

                    .text {
                        color: var(--c-white);
                        font-size: 16px;
                        line-height: normal;
                    }

                    .diagramImg {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                    }

                    .circleIcon {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
        }

        .btn {
            width: 100%;
            padding: 30px;
        }

        .whiteBlock {
            padding: 40px;
            border-radius: 30px;
            background-color: #f9f9f9;

            .whiteBlockTitle {
                width: 80%;
                margin-bottom: 20px;
                font-weight: var(--f-bold);
                font-size: 24px;
            }

            .text {
                margin-bottom: 30px;
                font-size: 16px;
                line-height: normal;
            }
        }
    }

    .bubble {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
    }
}

@media (--large-only) {
    .about {
        .container {
            margin: 100px 70px;
        }

        .title {
            margin-bottom: 30px;
            font-size: 24px;
        }

        .wrap {
            gap: 20px;

            .blocksWrap {
                gap: 16px;

                .blocks {
                    gap: 16px;

                    .blueBlock {
                        height: 500px;
                        padding: 40px 30px;

                        .blueBlockTitle {
                            font-size: 20px;
                        }

                        .text {
                            font-size: 14px;
                        }
                    }
                }
            }

            .btn {
                padding: 25px;
            }

            .whiteBlock {
                padding: 40px 30px;

                .whiteBlockTitle {
                    margin-bottom: 20px;
                    font-size: 20px;
                }

                .text {
                    margin-bottom: 22px;
                    font-size: 14px;
                }
            }
        }

        .bubble {
            width: 34%;
        }
    }
}

@media (--medium-only) {
    .about {
        .container {
            margin: 100px 20px;
        }

        .title {
            margin-bottom: 30px;
            font-size: 24px;
        }

        .wrap {
            grid-template-columns: auto;
            gap: 14px;

            .blocksWrap {
                gap: 14px;

                .blocks {
                    gap: 14px;

                    .blueBlock {
                        height: 500px;

                        .blueBlockTitle {
                            font-size: 20px;
                        }

                        .text {
                            font-size: 14px;
                        }
                    }
                }

                .btn {
                    padding: 20px;
                }
            }

            .whiteBlock {
                .whiteBlockTitle {
                    margin-bottom: 20px;
                    font-size: 20px;
                }

                .text {
                    margin-bottom: 22px;
                    font-size: 14px;
                }
            }
        }

        .bubble {
            display: none;
        }
    }
}

@media (--medium-until) {
    .about {
        .container {
            margin: 100px 10px;
        }

        .title {
            margin-bottom: 20px;
            font-size: 18px;
            text-align: left;
        }

        .wrap {
            grid-template-columns: auto;
            gap: 20px;

            .blocksWrap {
                gap: 20px;

                .blocks {
                    grid-template-columns: auto;
                    gap: 10px;

                    .blueBlock {
                        height: 460px;
                        padding: 30px 20px;

                        .blueBlockTitle {
                            font-size: 16px;
                        }

                        .text {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                }
            }

            .btn {
                padding: 15px;
                font-size: 14px;
                white-space: normal;
                word-break: break-word;
            }

            .whiteBlock {
                padding: 30px 20px;

                .whiteBlockTitle {
                    width: 100%;
                    font-size: 16px;
                }

                .text {
                    margin-bottom: 22px;
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }

        .bubble {
            display: none;
        }
    }
}
