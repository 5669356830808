.feedback {
    position: relative;
    margin: 200px 0 100px;

    .title {
        margin-bottom: 5vw;
        color: var(--c-grey3);
        font-weight: var(--f-bold);
        font-size: 32px;
        text-align: center;
    }

    .image {
        position: absolute;
        right: 0;
        bottom: -160px;
        z-index: -1;
    }
}

.content {
    display: flex;
    gap: 20px;

    .logo {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 20%;
        filter: contrast(40%) grayscale(100%);

        .img {
            display: block;
            width: 100%;
        }
    }

    .info {
        overflow-y: auto;
        max-height: 205px;
        padding: 0 70px;

        .name {
            color: var(--c-grey3);
            font-weight: var(--f-bold);
            font-size: 24px;
        }

        .post {
            margin-bottom: 20px;
            color: var(--c-grey3-80);
            font-weight: var(--f-bolder);
            font-size: 18px;
        }

        .text {
            max-width: 620px;
            color: var(--c-grey3);
            font-weight: var(--f-thiner);
            font-size: 18px;
            line-height: 32px;
            user-select: none;
        }
    }
}

@media (--large-only) {
    .feedback {
        .title {
            margin-bottom: 80px;
            font-size: 24px;
        }

        .image {
            overflow-x: hidden;
            width: 40%;
        }
    }
}

@media (--medium-only) {
    .feedback {
        margin: 120px 20px 80px;

        .title {
            margin: 0 0 80px 80px;
            font-size: 32px;
            text-align: start;
        }

        .image {
            right: -20px;
            overflow-x: hidden;
            width: 60%;
        }
    }

    .content {
        .logo {
            width: 30%;
            margin-top: 10px;
        }

        .info {
            width: 100%;
            padding: 0 20px;

            .name {
                margin-bottom: 10px;
                font-size: 18px;
                line-height: 24px;
            }

            .post {
                margin-bottom: 15px;
                font-size: 14px;
            }

            .text {
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}

@media (--medium-until) {
    .feedback {
        overflow: hidden;
        margin: 120px 0 80px;

        .title {
            margin: 0 0 70px 20px;
            font-size: 20px;
            text-align: start;
        }

        .image {
            display: none;
        }
    }

    .content {
        flex-direction: column;

        .info {
            width: 100%;
            padding: 0;

            .name {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 24px;
            }

            .post {
                margin-bottom: 20px;
                font-size: 14px;
            }

            .text {
                font-size: 14px;
                line-height: 24px;
            }
        }

        .logo {
            width: 40%;
        }
    }
}
