.pricingPlans {
    .plans {
        display: grid;
        grid-template-columns: repeat(3, 33.3%);
        gap: 10px;
        justify-content: space-around;
        min-width: 0;

        @media (--medium-until) {
            grid-template-columns: repeat(1, auto);
        }
    }
}
