.api {
    .subheader {
        display: flex;
        justify-content: space-between;
    }

    .items {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;

        .item {
            display: flex;
            flex-direction: column;
            gap: 1ch;
            align-items: center;
            justify-items: center;
            width: 30%;
            margin-bottom: 3vw;
            text-align: center;

            @media (--medium-until) {
                width: 40%;
            }

            @media (--small-until) {
                flex-direction: row;
                gap: 2ch;
                justify-items: flex-start;
                width: 100%;
                text-align: left;
            }

            svg {
                max-width: 33%;
            }

            h4 {
                font-weight: var(--f-bold);
            }

            p {
                font-size: var(--t-level4);
            }

            .descr {
                display: flex;
                flex-direction: column;
                gap: 1ch;
            }
        }
    }
}
