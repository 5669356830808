.pagination {
    display: flex;
    gap: 1ch;
    align-items: center;
    margin: 1ch;
    font-weight: var(--f-bold);
    font-size: var(--t-level4);

    .leftArrow,
    .rightArrow {
        color: var(--c-blue);
        cursor: pointer;
    }

    .rightArrow {
        transform: rotate(180deg);
    }

    .current {
        color: var(--c-blue);
    }

    .inactive {
        cursor: default;
        pointer-events: none;
    }

    .num {
        padding: 0 0.25em;
        cursor: pointer;

        a:any-link {
            color: inherit;
            text-decoration: none;
        }
    }

    svg {
        height: 0.85em;
    }
}
