.page {
    background-position: 50% 109%;
    background-size: 362%;
    background-repeat: no-repeat;
    visibility: hidden;

    @media (min-width: 240px) {
        background-position: 10% 90%;
        background-size: 168%;
    }

    @media (min-width: 850px) {
        background-position: 5% 95%;
        background-size: 168%;
    }

    @media (min-width: 1150px) {
        background-position: 188px 100%;
        background-size: 94%;
    }

    @media (min-width: 1270px) {
        background-position: 293px 100%;
        background-size: 99%;
    }

    @media (min-width: 1400px) {
        background-position: 372px 103%;
        background-size: 98%;
    }

    @media (min-width: 1650px) {
        background-position: 429px 103%;
        background-size: 79%;
    }

    @media (min-width: 1900px) {
        background-position: 557px 102%;
        background-size: 75%;
    }

    @media (min-width: 2300px) {
        background-position: 887px 106%;
        background-size: 62%;
    }

    @media (min-width: 2500px) {
        background-position: 1100px 106%;
        background-size: 59%;
    }

    &.visible {
        visibility: visible;
    }

    .header {
        position: relative;
        z-index: 2;
    }
}

.btn {
    display: flex;
    justify-content: center;
    margin-top: 4vh;
}
