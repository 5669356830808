.button {
    padding: 14px 35px;
    background-color: var(--c-blue);
    color: var(--c-white);
    font-weight: var(--f-bold);
    font-size: 16px;
    line-height: normal;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;

    @media (--large-until) {
        padding: 14px 30px;
        font-size: 14px;
    }
}
