.nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    box-sizing: border-box;
    width: 360px;
    padding-top: 115px;
    padding-right: 10px;
    padding-left: 80px;
    background-color: var(--c-blue);
    transition: transform ease-in-out 0.4s;
    transform: translateX(100%);
    z-index: 101;

    @media (--medium-until) {
        display: flex;
        flex-direction: column;
        width: 100vw;
        padding-top: 85px;
        padding-left: 65px;
    }

    &.active {
        transition: transform ease-in-out 0.4s;
        transform: translateX(0);
    }

    .close {
        position: absolute;
        top: 40px;
        right: 40px;
        padding: 16px;
        color: var(--c-white);
        cursor: pointer;

        &:hover svg {
            transform: rotate(180deg);
        }

        svg {
            width: 26px;
            transition: 0.3s ease-in-out;
            will-change: transform;
        }
    }

    .content {
        overflow: hidden;
        height: 100%;
        color: var(--c-white);

        @media (--medium-until) {
            flex-grow: 1;
            font-weight: var(--f-normal);
        }

        .scrollable {
            overflow-x: hidden;
            overflow-y: scroll;
            height: 100%;
            margin-right: -25px;
            padding-right: 25px;
        }

        .contacts {
            margin-top: 36px;
        }
    }

    .links {
        font-weight: var(--f-normal);
        font-size: 14px;
        line-height: 34px;

        @media (--medium) {
            font-size: 18px;
            line-height: 44px;
        }

        @media (min-width: 1024px) {
            font-size: 24px;
            line-height: 59px;
        }

        &.tight {
            line-height: 26px;

            @media (--medium) {
                line-height: 33px;
            }

            @media (min-width: 1024px) {
                line-height: 44px;
            }
        }

        &.inline {
            display: flex;
            align-items: center;
            margin: 1em 0;
            font-size: 14px;
            line-height: 17px;

            @media (--medium) {
                font-size: 18px;
                line-height: 22px;
            }

            .item:not(:first-child) {
                display: flex;
                align-items: center;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 1px;
                    height: 27px;
                    margin: 0 15px;
                    background-color: var(--c-white);
                }
            }
        }

        .item {
            list-style: none;

            .link {
                color: var(--c-white);
                text-decoration: none;
            }
        }
    }
}
